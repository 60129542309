import Color from '@arcgis/core/Color';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import { ActivityConfig } from 'activity/config/activity-config';
import { ActivityBox } from 'activity/model/activity-box';
import { Mailbox } from 'message/model/mailbox';
import { toolTypeList } from 'tool/tool';

import { ActionButtonType } from './action-button/action-button.component';

export class Constants {
  static readonly LANGUAGE_CODE = 'nl';
  static readonly FISHING_MASTER_CONFIG: ActivityConfig = {
    toolType: toolTypeList.fishingMaster,
    title: 'fishing-master.title',
  };
  static readonly FISHING_TOGETHER_CONFIG: ActivityConfig = {
    toolType: toolTypeList.fishingTogether,
    title: 'fishing-together.title',
  };
  static readonly MAILBOX_ICON = new Map<Mailbox, string>([
    ['INBOX', 'mail-unread'],
    ['SENT', 'cloud-upload'],
    ['CONCEPTS', 'pencil-outline'],
  ]);
  static readonly ACTIVITY_BOX_ICON = new Map<ActivityBox, string>([
    ['NEW', 'alert-circle'],
    ['OPEN', 'alarm'],
    ['UPCOMING', 'calendar-outline'],
    ['DONE', 'checkmark-circle'],
  ]);

  static readonly PRIMARY_COLOR_MARKER_SYMBOL = new SimpleMarkerSymbol({
    color: new Color([0, 104, 181]),
    outline: new SimpleLineSymbol({ color: [255, 255, 255] }),
  });

  static readonly GOOGLE_MAPS_URL =
    'https://www.google.com/maps/search/?api=1&query=#latitude,#longitude';

  static readonly INITIAL_TIME = '00:00';
  static readonly SVNL_BACKEND_DATE_FORMAT = 'yyyy-MM-dd';

  static readonly PDF_FIELD_GUIDE =
    '/assets/doc/veldgids-controle-visdocumenten.pdf';

  // Can we combine this with visplanner? Move this to a @svnl/ionic?
  static readonly ACTION_BUTTON_TYPE_ICON = new Map<ActionButtonType, string>([
    ['link', 'open-outline'],
    ['email', 'mail-outline'],
    ['info', 'information-circle-outline'],
  ]);

  static readonly SVNL_URL_FISHING_LAWS_AND_REGULATONS =
    'https://www.sportvisserijnederland.nl/vispas/visserijwet-en-regels';
}
